
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import Layout from '../components/Layout';
import Hero from '../components/Mobileapp/hero';
import allAPiData from '../api.js';
import { Pagination } from "react-pagination-bar";
import Seo from '../components/Seo';
import allApiData from "../api";
import SeoheadData from '../siteScriptData';

let url = 'https://outsourcingvasundhara.sfo3.cdn.digitaloceanspaces.com'

const TestimonialPage = () => {
  const [testimonials, setTestmonials] = useState([]);
  const [lengthTest, setLengthTest] = useState(12)
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    allAPiData.getTestimonials(currentPage).then((data) => {
      setLengthTest(data.data.total)
      setTestmonials(data.data.data);
    })
  }, [currentPage]);
  useEffect(() => {
    allApiData.aosinit()
  }, []);

  return (
    <>
      <Layout>
        <Seo SeoData={SeoheadData.testimonialsPageSeoData}></Seo>
        <section>
          <div className="testi">
            <Hero title=" Hear From Our Customers" def="" cta="Get a Quote">
              <div className="hero-img hide-animation-768 my-lg-5">
                <img src="../assets/img/testimonials/hero_img/client-testimonials.svg" className="wh-auto z-index img-fluid " alt="client-testimonials" width="743" height="504" />
                <img src="../assets/img/testimonials/hero_img/1.svg" className="img-fluid testimonials-img-1" alt="client-testimonials-one" />
                <img src="../assets/img/testimonials/hero_img/2.svg" className="img-fluid testimonials-img-2" alt="client-testimonials-two" />
                <img src="../assets/img/testimonials/hero_img/3.svg" className="img-fluid testimonials-img-3" alt="client-testimonials-three" />
                <img src="../assets/img/testimonials/hero_img/3.svg" className="img-fluid testimonials-img-4" alt="client-testimonials-four" />
              </div>
              <div className="show-main-img-768">
                <img src="../assets/img/testimonials/hero_img/client-testimonials-mobile.svg" className="wh-auto img-fluid" alt="client-testimonials-mobile" width="680" height="461" />
              </div>
            </Hero>
          </div>
          <Container className='pb-100 '>
            <div className='testimonial-page-box my-5'>
              <Row>
                {
                  testimonials && testimonials.map((data, i) => (
                    <Col lg={6} md={6} sm={12} className="mt-md-5 mt-sm-4 mt-3">
                      <div key={i} className="h-100">
                        <div className='testimonial-box d-flex flex-column justify-content-between' style={{ height: '100%' }} data-aos="zoom-in">
                          <div>
                            <div className='client d-flex align-items-center'>
                              <img src={`${url}/${data.icon_url}`} alt={data.name} width="120" height="120" className='wh-auto' />
                              <div className='client-name'>
                                <h4>{data.name}</h4>
                                <span>{data.industry}</span>
                              </div>
                            </div>
                            <div className='client-def'>
                              <p>{data.description}</p>
                            </div>
                          </div>
                          <div>
                            <img src={`${url}/${data.platform_logo}`} alt={data.name} className='mt-4' />
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))
                }
              </Row >
            </div >

            <Pagination
              initialPage={1}
              // itemPerPage={pagePostsLimit}
              onPageСhange={(pageNumber) => setCurrentPage(pageNumber)}
              totalItems={lengthTest}
              // pageNeighbours={2}
              itemsPerPage={6}
              prevLabel={'<<'}
              nextLabel={'>>'}
            />
          </Container>
        </section >
      </Layout >
    </>
  )
}

export default TestimonialPage
